import { Colours } from "@pasabi/ui-components";
import { IListResponse } from "@pasabi/ui-patterns";
import { AxiosError } from "axios";
import { RawLocation } from "vue-router";
import { Module as Mod } from "vuex";
import { APIError } from "../api/apierror";

export enum QueryTypes {
  Query_PositiveBiasCrossIPCombo = "positiveBiasCrossIPCombo",
  Query_SecondaryQuery = "secondaryQuery",
  Query_PositiveBiasLocalIPCAT = "positiveBiasLocalIPCAT",
  Query_FlaggedDataCAT = "catFlaggedData",
  Query_BehaviourAnalysis = "behaviourAnalysis",
  Query_Spam = "spam",
  Query_Scam = "scam",
}

export interface LoginData {
  username: string;
  password: string;
}

export interface AccountProfile {
  _id: string;
  username: string;
  forename: string;
  surname: string;
  middlename: string;
  email: string;
  avatar: string;
  locale: string;
  timezone: number;
  date_created: string;
  date_modified: string;
  initials: string;
}

export interface BusinessDetails {
  business_name: string;
  business_id: string;
}

// TODO deprecate this name as everything else is using Batch, alias created for now
export interface Snapshot {
  batchId: string;
  neoSchema: string;
  query: QueryTypes;
  batchStartDate: Date;
  batchEndDate: Date;
  dateCreated: Date;
  batchName: string;
}

export type Batch = Snapshot;

export interface APIBatch {
  _id: string;
  neoSchema: string;
  query: QueryTypes;
  batchStartDate: string;
  batchEndDate: string;
  dateCreated: string;
}

export interface BusinessOverviewScore {
  batchId: string;
  reviewsPercentage: number;
  profilesPercentage: number;
  action: boolean;
  actionNew: string;
}

export interface BusinessOverview {
  scores: BusinessOverviewScore[];
  reviews: Review[];
  profiles: Profile[];
  businesses: Business[];
  ips: BusinessIPsAggregate[];
  isps: BusinessISPsAggregate[];
  reviewsHistory: KeyCountTimeSeriesWithKeys;
  profilesHistory: KeyCountTimeSeriesWithKeys;
  reviewsSummary: KeyCount[];
  profilesSummary: KeyCount[];
}

export interface ProfileOverview {
  reviewsHistory: KeyCountTimeSeriesWithKeys;
  reviews: Review[];
  businesses: DashboardBusiness[];
  ips: IP[];
  isps: BusinessISPsAggregate[];
  profiles: Profile[];
}

export interface ProfileDetails {
  profile_id: string;
  email: string;
  activation_date: string;
  count_geoc: number;
  count_countries: number;
  count_cities: number;
}

export interface APIPagination {
  total: number;
  itemsPerPage: number;
  page: number;
  numberOfPages: number;
}

export interface PagedResponse<T> {
  pagination: APIPagination;
  entries: T[];
}

export interface Review {
  _id: string;
  batchId: string;
  review_id: string;
  abuse_velocity: string;
  activation_date: Date;
  active_tor: boolean;
  asn: string;
  batchEndDate: Date;
  batchStartDate: Date;
  bot_status: boolean;
  business_id: string;
  business_name: string;
  businesses: string[];
  cluster_id: number;
  clusters: string[];
  connectionTypeScore: number;
  connection_type: string;
  count_cities: number;
  count_countries: number;
  count_geoc: number;
  date: Date;
  disposable: boolean;
  distance: number;
  email: string;
  first_name: string;
  fraud_score: number;
  invitation_source: string;
  ipScore: number;
  ip_address: string;
  ip_city: string;
  ip_country: string;
  ip_region: string;
  isReviewSpam: boolean;
  isp: string;
  neoSchema: string;
  neo_business_id: number;
  posts: number;
  primary_detected_lang: string;
  profile_id: string;
  proxy: boolean;
  proxyScore: number;
  query: string;
  recent_abuse: boolean;
  report_reason?: string;
  reviews: string[];
  score: number;
  similar_emails: number;
  spam_scam4: string;
  spam_scam_tfidf2: number;
  status: string;
  text: string;
  tier: string;
  title: string;
  tor: boolean;
  totalReviewScore: number;
  totalReviewScorePercentage: number;
  translated: boolean;
  username: string;
  vpn: boolean;
  profanity?: boolean;
  profanity_score?: number;
  closeMatchesCount?: number;
  exactMatchesCount?: number;
  phone_match?: boolean;
  email_match?: boolean;
  labels?: string[];
  pii_array?: string[];
  dumbSpamOrScam?: string[];
  dumbKeywords?: string[];
}

export interface Profile {
  profile_id: string;
  username: string;
  email: string;
  shouldAction: boolean;
  profileReviewScorePercentage: number;
  totalProfileScorePercentage: number;
  containsSpam: boolean;
  activation_date: string;
  count_geoc: number;
  count_countries: number;
  count_cities: number;
  query: QueryTypes;
  orderScore: number;
  similar_emails: number;
  emailScore: number;
  recent_abuse?: boolean;
  disposable?: boolean;
  email_valid?: boolean;
  mobile_number?: string;
  mobile_number_valid?: boolean;
  mobile_number_active?: boolean;
  telephone_number_valid?: boolean;
  telephone_number_active?: boolean;
  labels?: string[];
  profileReviewLabels?: string[];
}

export interface ProfileGraph extends Profile {
  reviewsCount: number;
  ipsCount: number;
}

export interface Business {
  business_id: string;
  business_name: string;
  tier: string;
  action: boolean;
  actionNew: string;
  reviewsPercentage: number;
  profilesPercentage: number;
  query: QueryTypes;
  neoSchema: string;
  batchEndDate: string;
  orderScore: number;
}

export interface BusinessGraph extends Business {
  reviewsCount: number;
  ipsCount: number;
  profilesCount: number;
}

export interface Filter {
  key: string;
  values: Array<string | number | boolean>;
}

// eslint-disable-next-line
export interface IP {
  ip_address: string;
  isp: string;
  ip_country: string;
  ip_city: string;
  connection_type: string;
  fraud_score: number;
  ip_region: string;
  vpn: boolean;
  proxy: boolean;
  asn: string;
  query: QueryTypes;
  reviewsCount: number;
  profilesCount: number;
  businessesCount: number;
}

export interface IPGraph extends IP {
  reviewsCount: number;
  businessesCount: number;
  profilesCount: number;
}

export interface BusinessIPsAggregate extends IP {
  count: number;
}

export interface ISP {
  isp: string;
  ips: string[];
  reviewsCount: number;
  ipsCount: number;
  profilesCount: number;
  businessesCount: number;
}

export interface BusinessISPsAggregate extends ISP {
  isp: string;
  count: number;
  ips: string[];
}

export interface PhoneNumber {
  phone_number: string;
  count: number;
}

export interface Pagination {
  page: number;
  size: number;
  start: number;
  end: number;
}

export interface SortValue {
  key: string;
  descending: boolean;
}

export interface FilterUpdate {
  key: string;
  value: string | string[];
}

export interface FilterValues {
  [key: string]: string[] | string;
}

export interface PagedSortCriteria {
  page: number;
  itemsPerPage: number;
  sort?: SortValue;
  filters?: FilterValues;
}

export enum RequestState {
  Idle = 1,
  Loading,
  Error,
  Done,
}

export type ResponseError = AxiosError | APIError | Error | null;

export type BusinessBatchRequestParams = { businessId: string; batchId: string };

export type SimpleBatchRequestParams = { batchId: string };

export type BusinessRequestParams = { businessId: string };

export type CommentTagRequestParams = { contentType: CommentTagType; contentId: string };

export type AddCommentRequestParams = {
  contentType: CommentTagType;
  contentId: string;
  text: string;
};

export type TagsRequestParams = { contentType: CommentTagType };

export type UpdateTagsRequestParams = {
  contentType: CommentTagType;
  contentId: string;
  tags: string[];
};

export type ProfileBatchRequestParams = { profileId: string; batchId: string };

export interface HeadlineStat {
  type: string;
  value: number;
  count: number;
  change?: number;
}

export interface KeyCount {
  key: string;
  count: number;
}

export interface KeyCountTimeSeries {
  date: string;
  entries: KeyCount[];
}

export interface KeyCountTimeSeriesWithKeys {
  keys: string[];
  data: KeyCountTimeSeries[];
}

export interface DashboardBusiness {
  _id: string;
  action: boolean;
  actionNew: string;
  profilesPercentage: number;
  reviewsPercentage: number;
  business_name: string;
  business_id: string;
}

export interface DashboardProfile {
  shouldAction: boolean;
  username: string;
  profile_id: string;
  email: string;
  count: number;
}

export interface DashboardReview {
  business_name: string;
  reviewId: string;
  email: string;
  status: string;
  totalReviewScore: number;
  totalReviewScorePercentage: number;
  title: string;
  text: string;
  isReviewSpam: boolean;
}

export interface DashboardIP {
  isp: string;
  reviewsCount: number;
  ip_address: string;
  ip_country: string;
  connection_type: string;
}

// this is a type alias to allow subclassing vuex-decorator-modules, I don't think I can fix
// the generic type so it can be used from any child so setting to any
// eslint-disable-next-line
export type ParentModuleType = Mod<ThisType<any>, any>;

export interface User {
  _id: string;
  // forename: string;
  // surname: string;
  fullname: string;
}

export interface CommentData {
  _id: string;
  id: string;
  date_created: Date;
  date_modified: Date;
  author: User;
  text: string;
}

export enum CommentTagType {
  business = "business",
  profile = "profile",
}

export interface FilterData {
  alias?: string;
  key: string;
  multiple: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values?: any[];
}

export enum OverviewDetailsComponents {
  span = "span",
  UiPercentCircle = "ui-percent-circle",
}

export interface OverviewDetails {
  label: string;
  component?: OverviewDetailsComponents;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: Record<string, any>;
  content?: string | number;
  linkTo?: RawLocation;
}
export interface IDashboardSumaryData {
  count: number;
  total?: number;
  batch?: APIBatch;
  previous?: {
    count: number;
    change: number;
    batch: APIBatch;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type IDashboardListData = IListResponse<any>["data"]["payload"];

export interface IDashboardGroupCounterData {
  key: string;
  count: number;
}

export type IDashboardTimeSeriesData = {
  key: string;
  count: number;
} & {
  [otherCount: string]: number;
};

export interface IDashboardBaseLayout {
  label?: string;
  help?: string;
  icon?: string;
  rows?: number;
  cols?: number;
  highlight?: typeof Colours[number];
  glow?: typeof Colours[number];
}

export interface IDashboardSummaryLayout extends IDashboardBaseLayout {
  type: "number" | "time-diff";
}

export interface IDashboardGroupCounterLayout extends IDashboardBaseLayout {
  type: "pie" | "bar";
  style?: string;
}

export interface IDashboardPieLayout extends IDashboardGroupCounterLayout {
  type: "pie";
  style: "flat" | "flower";
}

export interface IDashboardBarLayout extends IDashboardGroupCounterLayout {
  type: "bar";
  style: "vertical" | "horizontal";
}

export interface IDashboardListLayout extends IDashboardBaseLayout {
  type:
    | "profiles"
    | "profiles_geoc"
    | "profiles_similar_emails"
    | "businesses"
    | "ips"
    | "isps"
    | "phone_numbers"
    | "reviews";
}

export interface IDashboard {
  _id: string;
  label: string;
  metrics: IDashboardMetric[];
  layout?: { cols?: number };
}

export interface IDashboardBaseMetric {
  key: string;
  id?: string;
  type: "summary" | "timeSeries" | "groupCounter" | "list";
  layout?: IDashboardBaseLayout;
}

export interface IDashboardSummaryMetric extends IDashboardBaseMetric {
  type: "summary";
  layout?: IDashboardSummaryLayout;
}

export interface IDashboardTimeSeriesMetric extends IDashboardBaseMetric {
  type: "timeSeries";
}

export interface IDashboardGroupCounterMetric extends IDashboardBaseMetric {
  type: "groupCounter";
  layout?: IDashboardPieLayout | IDashboardBarLayout;
}

export interface IDashboardListMetric extends IDashboardBaseMetric {
  type: "list";
  layout?: IDashboardListLayout;
}

export type IDashboardMetric =
  | IDashboardSummaryMetric
  | IDashboardTimeSeriesMetric
  | IDashboardGroupCounterMetric
  | IDashboardListMetric;

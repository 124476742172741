
import { IGraphNode } from "@pasabi/ui-blossom/types/interfaces/graph";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UiButton, UiPreloader } from "@pasabi/ui-components";
import { RawLocation } from "vue-router";
import { ProfileDetailsStore } from "../../store/modules/ProfileDetails";
import { ProfileGraph } from "../../types";

@Component({
  components: {
    UiButton,
    UiPreloader,
  },
})
export default class ProfileGraphNode extends Vue {
  store = ProfileDetailsStore;

  @Prop({ required: true }) node!: IGraphNode;
  @Prop({ required: true }) type!: "overlay" | "overview";

  get profileId() {
    return this.node.properties.profile_id;
  }

  @Watch("profileId", { immediate: true })
  profileIdChanged() {
    if (this.profileId) {
      this.loadData();
    }
  }

  loadData() {
    this.store.fetchItemAssociation({ id: this.profileId, key: "graph" });
  }

  get properties(): { label: string; value: string | number; size?: number }[] {
    if (this.profile) {
      return [
        {
          label: this.$t("graph.profiles.fields.id").toString(),
          value: this.profile.profile_id,
          size: 100,
        },
        { label: this.$t("graph.profiles.fields.name").toString(), value: this.profile.username },
        {
          label: this.$t("graph.profiles.fields.activationDate").toString(),
          value: new Date(this.profile.activation_date).toLocaleString(undefined, {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }),
        },
        {
          label: this.$t("graph.profiles.fields.geoc").toString(),
          value: this.profile.count_geoc,
        },
        {
          label: this.$t("graph.profiles.fields.finalScore").toString(),
          value: `${Math.round(this.profile.totalProfileScorePercentage * 100)}%`,
        },
        {
          label: this.$t("graph.profiles.fields.action").toString(),
          value: this.$t(`filters.shouldAction.${this.profile.shouldAction}`).toString(),
        },
        {
          label: this.$t("graph.profiles.fields.reviewsCount").toString(),
          value: this.profile.reviewsCount,
        },
        {
          label: this.$t("graph.profiles.fields.ipsCount").toString(),
          value: this.profile.ipsCount,
        },
      ];
    }
    return [];
  }

  get isLoading() {
    return this.store.isLoadingAssociation({ id: this.profileId, key: "graph" });
  }

  get isLoaded() {
    return this.store.isAsssociationLoaded({ id: this.profileId, key: "graph" });
  }

  get loadingError() {
    return this.store.associationLoadingError({ id: this.profileId, key: "graph" });
  }

  get profile(): ProfileGraph {
    return this.store.associationData({ id: this.profileId, key: "graph" });
  }

  get profileLocation(): RawLocation {
    return {
      name: "profile>details",
      params: {
        ...this.$router.currentRoute.params,
        queryType: "positiveBiasCrossIPCombo",
        id: this.profileId,
      },
    };
  }
}


import { IGraphNode } from "@pasabi/ui-blossom/types/interfaces/graph";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UiButton, UiPreloader } from "@pasabi/ui-components";
import { RawLocation } from "vue-router";
import { ReviewDetailsStore } from "../../store/modules/ReviewDetails";

const REVIEW_TRUCATE_BY_TYPE = {
  overlay: 150,
  overview: 250,
};

@Component({
  components: {
    UiButton,
    UiPreloader,
  },
})
export default class ReviewGraphNode extends Vue {
  store = ReviewDetailsStore;

  @Prop({ required: true }) node!: IGraphNode;
  @Prop({ required: true }) type!: "overlay" | "overview";

  get reviewId() {
    return this.node.properties.review_id;
  }

  @Watch("reviewId", { immediate: true })
  reviewIdChanged() {
    if (this.reviewId) {
      this.loadData();
    }
  }

  loadData() {
    this.store.fetchItem({ id: this.reviewId });
  }

  get properties(): { label: string; value: string | number; size?: number }[] {
    if (this.review) {
      return [
        {
          label: this.$t("graph.reviews.fields.id").toString(),
          value: this.review.review_id,
          size: 100,
        },
        {
          label: this.$t("graph.reviews.fields.content").toString(),
          value:
            this.review.text.length > 250
              ? `${this.review.text.substring(0, REVIEW_TRUCATE_BY_TYPE[this.type] || 250)}...`
              : this.review.text,
          size: 100,
        },
        {
          label: this.$t("graph.reviews.fields.spamScam").toString(),
          value: this.review.isReviewSpam?.toString(),
        },
        {
          label: this.$t("graph.reviews.fields.reviewScore").toString(),
          value: this.review.totalReviewScore,
        },
      ].filter(({ value }) => value != undefined);
    }
    return [];
  }

  get isLoading() {
    return this.store.isLoading({ id: this.reviewId });
  }

  get isLoaded() {
    return this.store.isLoaded({ id: this.reviewId });
  }

  get loadingError() {
    return this.store.loadingError({ id: this.reviewId });
  }

  get review() {
    return this.store.item(this.reviewId);
  }

  get reviewLocation(): RawLocation {
    return {
      name: "review>details",
      params: {
        ...this.$router.currentRoute.params,
        queryType: "positiveBiasCrossIPCombo",
        id: this.reviewId,
      },
    };
  }

  get profileLocation(): RawLocation | undefined {
    if (this.review) {
      return {
        name: "profile>details",
        params: {
          ...this.$router.currentRoute.params,
          queryType: "positiveBiasCrossIPCombo",
          id: this.review.profile_id,
        },
      };
    }
    return undefined;
  }
}


import { IGraphNode } from "@pasabi/ui-blossom/types/interfaces/graph";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UiButton, UiPreloader } from "@pasabi/ui-components";
import { RawLocation } from "vue-router";
import { IPDetailsStore } from "../../store/modules/IPDetails";
import { IPGraph } from "../../types";
import { getCountryNameFromCode } from "../../i18n";

@Component({
  components: {
    UiButton,
    UiPreloader,
  },
})
export default class IPGraphNode extends Vue {
  store = IPDetailsStore;

  @Prop({ required: true }) node!: IGraphNode;
  @Prop({ required: true }) type!: "overlay" | "overview";

  get ipAddress() {
    return this.node.properties.ip_address;
  }

  @Watch("ipAddress", { immediate: true })
  ipAddressChanged() {
    if (this.ipAddress) {
      this.loadData();
    }
  }

  loadData() {
    this.store.fetchItemAssociation({ id: this.ipAddress, key: "graph" });
  }

  get properties(): { label: string; value: string | number; size?: number }[] {
    if (this.ip) {
      return [
        {
          label: this.$t("graph.ips.fields.isp").toString(),
          value: this.ip.isp,
        },
        {
          label: this.$t("graph.ips.fields.ipScore").toString(),
          value: this.ip.fraud_score,
        },
        {
          label: this.$t("graph.ips.fields.vpn").toString(),
          value: this.ip.vpn.toString(),
        },
        {
          label: this.$t("graph.ips.fields.proxy").toString(),
          value: this.ip.proxy.toString(),
        },
        {
          label: this.$t("graph.ips.fields.country").toString(),
          value: getCountryNameFromCode(this.ip.ip_country),
        },
        {
          label: this.$t("graph.ips.fields.reviewsCount").toString(),
          value: this.ip.reviewsCount,
        },
        {
          label: this.$t("graph.ips.fields.businessesCount").toString(),
          value: this.ip.businessesCount,
        },
        {
          label: this.$t("graph.ips.fields.profilesCount").toString(),
          value: this.ip.profilesCount,
        },
      ];
    }
    return [];
  }

  get isLoading() {
    return this.store.isLoadingAssociation({ id: this.ipAddress, key: "graph" });
  }

  get isLoaded() {
    return this.store.isAsssociationLoaded({ id: this.ipAddress, key: "graph" });
  }

  get loadingError() {
    return this.store.associationLoadingError({ id: this.ipAddress, key: "graph" });
  }

  get ip(): IPGraph {
    return this.store.associationData({ id: this.ipAddress, key: "graph" });
  }

  get ipLocation(): RawLocation {
    return {
      name: "ip>details",
      params: {
        ...this.$router.currentRoute.params,
        queryType: "positiveBiasCrossIPCombo",
        id: this.ipAddress,
      },
    };
  }
}
